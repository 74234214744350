<template>
  <section>
    <p>NOSSA <strong>EMPRESA</strong></p>
  </section>
  <div class="about">
    <ul>
      <li><img src="@/assets/galeria/img01.webp" height="400" width="400" alt=""></li>
      <li>
        <p>A <strong> GTX Guincho</strong> é uma empresa que oferece serviços de transporte e reboque de veículos em Indaiatuba e regiões próximas. O nosso diferencial está na qualidade e excelência dos nossos serviços, que são realizados por uma equipe de profissionais altamente treinados e qualificados pela nossa própria empresa. Estamos disponíveis 24 horas por dia para atender as necessidades dos nossos clientes, garantindo a sua tranquilidade em qualquer hora do dia ou da noite.</p>
    <p>Nosso objetivo principal é proporcionar um atendimento de qualidade e eficiente, trabalhando com dedicação e foco total para superar as expectativas dos nossos clientes. Nosso compromisso é sempre oferecer um serviço de excelência, com rapidez, segurança e eficiência. Para isso, contamos com equipamentos modernos e tecnologia avançada, que nos permitem realizar os serviços de forma rápida e precisa.</p>
    <p>A <strong>GTX Guincho</strong> se preocupa com a satisfação dos seus clientes e por isso, trabalhamos com total transparência e comprometimento em cada serviço realizado. Nossos profissionais são treinados e capacitados para oferecer um atendimento personalizado, entendendo as necessidades de cada cliente e buscando sempre a solução mais adequada para cada situação.</p>
    <p>Com a <strong>GTX Guincho</strong>, você pode ter a certeza de um serviço de qualidade, eficiência e segurança. Seja qual for a sua necessidade em transporte e reboque de veículos, conte conosco para atendê-lo de forma rápida e eficiente, sempre com a preocupação em superar as expectativas dos nossos clientes.</p>
    <a href="http://" target="_blank" class="btn-banner" rel="noopener noreferrer"><span>PEÇA UM ORÇAMENTO</span></a>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
ul{
  *{
    overflow: hidden;
  }
  max-width: 90%!important;
  margin: 0 auto!important;
  padding: 0!important;
  display: grid;
  grid-template-columns: repeat(2,calc(100%/2));
  column-gap: 2rem;
  @media only screen and (max-width: 600px) {
    max-width: 100%!important;
    grid-template-columns: repeat(1,100%);
  }
  li{
    height: auto!important;
    p{
      text-align: left;
    }
    padding:1rem 0!important;
  *{
      margin:2rem 0!important;
    }
    img{
      max-height: 100%!important;
      height: auto!important;
      width: auto!important;
    }
  }
}
</style>
